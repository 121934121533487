




















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  deleteClient,
  deleteClientBulk,
  exportClients,
  getClients,
  getClientsCount
} from '@/api/clients'
import {
  createFilter,
  dateFilter,
  getSortItems,
  ignoreCaseSearchField,
  multiSelectFilter,
  personalFilter,
  prepareListFilters,
  searchFieldConcat, searchFieldConcatReverse,
  singleSelectFilter,
  usersFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import {
  addClients,
  removeClients,
  getGroups,
  getRemoteGroups
} from '@/api/groups'
import { foundFromList, professions } from '@/utils/property'

@Component({
  name: 'ClientList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private groups = []
  private listLoading = true
  private showFilters = false
  private showGroupDrawer = false
  private groupsLoading = false
  private group: any = null
  private search = ''
  private selected: any = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private hasPermission = hasPermission
  private searchFields = [
    searchFieldConcat(),
    searchFieldConcatReverse(),
    ignoreCaseSearchField('main_phone'),
    ignoreCaseSearchField('secondary_phone'),
    ignoreCaseSearchField('main_email'),
    ignoreCaseSearchField('translations.notes'),
    searchFieldConcat('user.'),
    searchFieldConcatReverse('user.')
  ]

  private filters: any = [
    singleSelectFilter({
      label: 'filters.foundFrom',
      key: 'found_from',
      modelValue: null,
      transKey: 'found_from',
      list: foundFromList
    }),
    singleSelectFilter({
      label: 'filters.profession',
      key: 'profession',
      modelValue: null,
      transKey: 'profession',
      list: professions
    }),
    multiSelectFilter({
      lazy: true,
      key: 'groups.id',
      label: 'filters.groups',
      apiEndpoint: getGroups,
      searchFields: [ignoreCaseSearchField('title')]
    })
  ]

  created() {
    this.getQueryParams()
    this.getList()
    if (hasPermission(['LIST_OTHERS_CLIENTS'])) {
      this.filters.push(personalFilter({
        key: 'user_id',
        label: 'filters.personalClients'
      }))
      this.filters.push(usersFilter())
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private getQueryParams() {
    const query: any = this.$route.query || {}
    this.filters.push(dateFilter({
      from: query.from ? new Date(Number(query.from)) : null,
      to: query.to ? new Date(Number(query.to)) : null
    }))
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteClient({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteClientBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: this.searchFields,
      filters: filters
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getClientsCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/client/edit/' + id)
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async modifyGroup(action: string) {
    if (!this.group?.id || !['save', 'delete'].includes(action)) {
      return
    }
    this.listLoading = true
    try {
      const data = {
        source_id: this.group?.id,
        relation_ids: this.selected.map((item: any) => {
          return item.id
        })
      }
      if (action === 'save') {
        await addClients(data)
        await successMsg('actions.apiSuccessSave')
      } else if (action === 'delete') {
        await removeClients(data)
        await successMsg('actions.apiSuccessDelete')
      }
      this.group = null
      this.showGroupDrawer = false
      await this.getList()
    } catch (err) {}
    this.listLoading = false
  }

  private async getRemoteClientsList(query: string) {
    this.groupsLoading = true
    this.groups = await getRemoteGroups(query)
    this.groupsLoading = false
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportClients(this.selected)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
      case 'manage_groups': {
        this.showGroupDrawer = true
        break
      }
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getClients(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
